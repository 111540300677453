import React from "react";
import { ModalWrapper } from "../../../components/ModalWrapper";
import ReferenceAnalysisDataReview from "./ReferenceAnalysisDataReview";
import { SignalReportSubmissionCard } from "../../../components";

export const ReferenceAnalysisReviewModal = ({ onClose, contentData }) => {
  const isOpen = !!contentData;

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <ModalWrapper
      title={"Reference analysis signal report"}
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <SignalReportSubmissionCard data={contentData} />
      <ReferenceAnalysisDataReview data={contentData} />
    </ModalWrapper>
  );
};
