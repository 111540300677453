import { convertISOToReadableDate } from "../../helpers";
import Jsontableify from "jsontableify";
import Parser from "html-react-parser";
import "./SignalReportSubmissionCard.css";

const formatReport = (data) => {
  const { timestamp, journal_name, manuscript_id, title } = data;

  return {
    Date: convertISOToReadableDate(timestamp, "DD MMM YYYY HH:mm"),
    Journal: journal_name,
    "Manuscript ID": manuscript_id,
    Title: title,
  };
};

const renderCardContent = (data) => {
  if (!data) return null;

  const reportData = formatReport(data);
  const jt = new Jsontableify();
  const content = jt.toHtml(reportData).html;

  return <div className="modal_table_wrapper">{Parser(content)}</div>;
};

export const SignalReportSubmissionCard = ({ data }) => {
  return (
    <div className="bg-light rounded shadow-sm mb-3">
      {renderCardContent(data)}
    </div>
  );
};
