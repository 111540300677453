import { useEffect, useState, useCallback } from "react";
import {
  MDBContainer,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import ManuscriptsTab from "./tabs/ManuscriptsTab";
import { CountCard } from "../../components";
import { showErrorToast, getDuplicateSubmissionsCount } from "../../helpers";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";

const TAB_CONFIG = [
  {
    key: "inbox",
    label: "Inbox",
    type: "wo_status",
    component: ManuscriptsTab,
  },
  {
    key: "all",
    label: "All",
    type: "all",
    component: ManuscriptsTab,
  },
  {
    key: "matched",
    label: "Matched (first submission)",
    type: "matched",
    component: ManuscriptsTab,
  },
  {
    key: "triggers",
    label: "Matching (trigger submission)",
    type: "triggers",
    component: ManuscriptsTab,
  },
];

const DuplicateSubmissions = () => {
  const [currentTab, setCurrentTab] = useState(TAB_CONFIG[0].key);
  const [isLoading, setIsLoading] = useState(false);
  const [manuscriptsCount, setManuscriptsCount] = useState(null);

  useEffect(() => {
    const fetchCardData = async () => {
      setIsLoading(true);
      try {
        const { data: { total = 0 } = {} } = await getDuplicateSubmissionsCount(); 
        setManuscriptsCount(total);
      } catch (error) {
        console.error(error);
        showErrorToast();
      } finally {
        setIsLoading(false);
      }
    };

    fetchCardData();
  }, []);

  const handleTabClick = useCallback((tabKey) => {
    if (currentTab !== tabKey) {
      setCurrentTab(tabKey);
    }
  }, [currentTab]);

  return (
    <MDBContainer className="my-5">
      <SpinnerOverlay active={isLoading}>
        <div className="d-flex justify-content-center m-5">
          <CountCard
            count={manuscriptsCount ?? 0}
            title="Matches"
            subtitle="Current matches"
          />
        </div>
      </SpinnerOverlay>

      <MDBTabs className="mb-3">
        {TAB_CONFIG.map(({ key, label }) => (
          <MDBTabsItem key={key} onClick={() => handleTabClick(key)}>
            <MDBTabsLink active={currentTab === key}>{label}</MDBTabsLink>
          </MDBTabsItem>
        ))}
      </MDBTabs>

      <MDBTabsContent>
        {TAB_CONFIG.map(({ key, component: TabComponent, type }) => (
          <MDBTabsPane key={key} show={currentTab === key}>
            <TabComponent
              isActiveTab={currentTab === key}
              type={type}
            />
          </MDBTabsPane>
        ))}
      </MDBTabsContent>
    </MDBContainer>
  );
};

export default DuplicateSubmissions;
