import {
  renderCardSection,
  renderAnyHitDetails,
  renderBadActorDetails,
} from "./WatchlistDataReviewUtils.js";
import { getWatchlistData } from "./watchlistUtils";

const WatchlistDataReview = ({ data }) => {
  const watchlistData = getWatchlistData(data);
  return (
    <>
      {renderCardSection(
        watchlistData.watchlistFakeEmailDomains,
        "Fake e-mail domains",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistFakeAffiliationNames,
        "Fake affiliation names",
        renderAnyHitDetails,
        <>
          Watchlist — The STM Integrity Hub maintains a list of fake email
          domains and institution names that were involved in paper mill
          activities. A red flag indicates that a match was found.
        </>
      )}
      {renderCardSection(
        watchlistData.watchlistFakeNamesOrEmailAddresses,
        "Fake names or email addresses",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistDisposableEmailDomains,
        "Disposable e-mail domains",
        renderAnyHitDetails,
        <>
          Watchlist — The Hub maintains a list of fake email domains that were
          involved in paper mill activities. Domains are sourced from publishers
          and Anna Abalkina. A red flag indicates that a match was found.
        </>
      )}
      {renderCardSection(
        watchlistData.watchlistBadActors,
        "Bad actors",
        renderBadActorDetails
      )}
      {renderCardSection(
        watchlistData.watchlistMetadataSuspects,
        "Metadata suspects",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistManuscriptsOfferedForSale,
        "Manuscripts offered for sale",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistSuspectArticles,
        "Suspect articles",
        renderAnyHitDetails
      )}
      {renderCardSection(
        watchlistData.watchlistChatGPTOutput,
        "GenAI response",
        renderAnyHitDetails,
        <>
          A collection of phrases that indicate the use of GenAI. The database
          is maintained by by Dr. Guillaume Cabanac.
        </>
      )}
    </>
  );
};

export default WatchlistDataReview;
