import { MDBBtn, MDBIcon, MDBTooltip } from "mdb-react-ui-kit";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  flex-basis: calc(50% - 0.25em);
  max-width: calc(50% - 0.25em);
`;

const ActionButton = ({ title, color, icon, onClick }) => (
  <ButtonWrapper>
    {title ? (
      <MDBTooltip tag="span" title={title} placement="top">
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MDBBtn style={{ backgroundColor: color }} floating onClick={onClick}>
            <MDBIcon fas icon={icon} />
          </MDBBtn>
        </div>
      </MDBTooltip>
    ) : (
      <MDBBtn style={{ backgroundColor: color }} floating onClick={onClick}>
        <MDBIcon fas icon={icon} />
      </MDBBtn>
    )}
  </ButtonWrapper>
);

export default ActionButton;
