import { ModalWrapper } from "../../../components/ModalWrapper";
import { SignalReportSubmissionCard } from "../../../components";
import PubpeerDataReview from "./PubpeerDataReview";

const PubpeerReviewModal = ({ contentData, onClose }) => {
  const isOpen = !!contentData;

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalWrapper title={"Pubpeer"} isOpen={isOpen} onClose={handleClose}>
      <SignalReportSubmissionCard data={contentData} />
      <PubpeerDataReview data={contentData} />
    </ModalWrapper>
  );
};

export { PubpeerReviewModal };
