import { axiosInstance } from "./axiosSetup";

export async function getScreenedManuscripts(startrow, maxrows, filter) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      maxrows: maxrows,
      startrow: startrow,
      filter: filter,
    },
    path: "/reports/screened/manuscripts",
    method: "get",
  });
}

export async function fetchManuscriptCounts() {
  const [duplicateSubmissionsResponse, ambientScreenedResponse] = await Promise.all([
    axiosInstance.post('hub-api/proxy', {
      path: "/reports/dupsub/count",
      method: "get",
    }),
    axiosInstance.post('hub-api/proxy', {
      path: "/reports/ambient/count",
      method: "get",
    }),
  ]);

  return {
    duplicateSubmissions: duplicateSubmissionsResponse.data?.total ?? 0,
    ambientScreened: ambientScreenedResponse.data?.total ?? 0,
  };
}
