import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import { camelCaseToSentenceCase } from '../../../helpers'

export const renderListItems = (items, renderItem) => {
  return (
    <MDBListGroup>
      {items?.map((item, index) => (
        <MDBListGroupItem key={index} className="d-flex align-items-start">
          {renderItem(item)}
        </MDBListGroupItem>
      ))}
    </MDBListGroup>
  );
};

export const renderWordFilePropertyItem = (item) => {
  const { message, details } = item;

  const cleanMessage = message.replace(/\s*\(.*?\)/g, '');
  const updatedDetails = details
    ? Object.fromEntries(
      Object.entries(details).map(([key, value]) => [
        key === 'revision' ? 'revisions' : key,
        value,
      ])
    )
    : null;

  return (
    <div className="p-2">
      {cleanMessage}<br />
      {updatedDetails && (
        <ul className="mt-2 list-unstyled">
          {Object.entries(updatedDetails).map(([key, value]) => (
            <li className="ms-4" key={key}>
              <strong>{camelCaseToSentenceCase(key)}:</strong> {value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};