export const dropdownOptions = [
  {
    label: "Confirm duplicate & report to other publisher(s)",
    value: "duplicate confirmed",
  },
  {
    label: "Paper mill suspect & report as confirmed to other publisher(s)",
    value: "suspect paper mill",
  },
  {
    label:
      "False positive, remove submission & report to other publisher(s) and STM",
    value: "false positive",
  },
  {
    label: "Legitimate duplicate, ignore",
    value: "ignored",
  },
  {
    label: "Hide from inbox",
    value: "hidden",
  },
];
