import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import { InfoTooltip } from "../../../components";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";

export const renderField = (label, value) => {
  return value ? (
    <p>
      <strong>{label}:</strong> {value}
    </p>
  ) : null;
};

export const renderAnyHitDetails = (item, index, array) => (
  <div key={index}>
    {renderField("Value", item?.value)}
    {renderField("Provided by", item?.providedby)}
    {renderField("Timestamp", item?.timestamp)}
    {renderField("Comment", item?.comment)}
    {index < array.length - 1 && <hr className="hr" />}
  </div>
);

export const renderBadActorDetails = (item, index, array) => (
  <div key={index}>
    {renderField("Name", item?.value?.name)}
    {renderField("Institution", item?.value?.institution)}
    {renderField("Email", item?.value?.email)}
    {renderField("Phone", item?.value?.phone)}
    {renderField("ORCID", item?.value?.ORCID)}
    {renderField("IP address", item?.sourceValue?.ip)}
    {renderField("Breach type", item?.breach)}
    {renderField("Provided by", item?.providedby)}
    {renderField("Timestamp", item?.timestamp)}
    {renderField("Comment", item?.comment)}
    {index < array.length - 1 && <hr className="hr" />}
  </div>
);

export const renderCardSection = (
  items,
  title,
  renderFunction,
  tooltipText = null
) => {
  if (items.length === 0) return null;

  return (
    <MDBCard className="mt-3">
      <MDBCardHeader className="d-flex">
        <ReportActionButtons signals={["watchlist"]} showTooltip={false} />
        <MDBCardTitle className="pt-2 ps-2">
          {title}
          <InfoTooltip
            tooltipText={
              <>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </>
            }
          />
          </MDBCardTitle>
      </MDBCardHeader>
      <MDBCardBody>
        {items.map((item, index, array) => renderFunction(item, index, array))}
      </MDBCardBody>
    </MDBCard>
  );
};
