export const dropdownOptions = [
  {
    label: 'Confirm duplicate',
    value: 'duplicate confirmed'
  },
  {
    label: 'Paper mill suspect',
    value: 'suspect paper mill'
  },
  {
    label: 'False positive',
    value: 'false positive'
  },
  {
    label: 'Hide from my list',
    value: 'hidden'
  }
];
