import { watchlistSignals } from "../../../constants";

export const getItemsByType = (contentData, type) => {
  return (
    contentData?.screening_outcomes?.find((x) => x.type === type)?.items ?? []
  );
};

export const getWatchlistData = (contentData) => {
  return {
    watchlistFakeEmailDomains: getItemsByType(
      contentData,
      "watchlistFakeEmailDomains"
    ),
    watchlistFakeAffiliationNames: getItemsByType(
      contentData,
      "watchlistFakeAffiliationNames"
    ),
    watchlistFakeNamesOrEmailAddresses: getItemsByType(
      contentData,
      "watchlistFakeNamesOrEmailAddresses"
    ),
    watchlistDisposableEmailDomains: getItemsByType(
      contentData,
      "watchlistDisposableEmailDomains"
    ),
    watchlistBadActors: getItemsByType(contentData, "watchlistBadActors"),
    watchlistSuspectArticles: getItemsByType(
      contentData,
      "watchlistSuspectArticles"
    ),
    watchlistMetadataSuspects: getItemsByType(
      contentData,
      "watchlistMetadataSuspects"
    ),
    watchlistManuscriptsOfferedForSale: getItemsByType(
      contentData,
      "watchlistManuscriptsOfferedForSale"
    ),
    watchlistChatGPTOutput: getItemsByType(
      contentData,
      "watchlistChatGPTOutput"
    ),
  };
};

export const formatScreeningOutcomeHits = (screeningOutcomes) => {
  if (!screeningOutcomes) return null;
  const labelMap = watchlistSignals.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});

  const formattedStatus = screeningOutcomes
    .map(({ type, items }) => {
      const label = labelMap[type];
      return label ? `${label}&nbsp;(x${items.length})` : null;
    })
    .join("<br>");

  return formattedStatus;
};

export const extractUniqueTypes = (data) => {
  const allTypes = data.flatMap((item) =>
    item.screening_outcomes.map((outcome) => outcome.type)
  );
  const uniqueTypes = [...new Set(allTypes)];
  return uniqueTypes;
};

export const formatScreeningOutcomeHitsPlainText = (screeningOutcomes) => {
  if (!screeningOutcomes) return "";
  const labelMap = watchlistSignals.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});

  const formattedStatus = screeningOutcomes
    .map(({ type, items }) => {
      const label = labelMap[type];
      return label ? `${label} (x${items.length})` : null;
    })
    .filter(Boolean)
    .join("\n");

  return formattedStatus;
};
