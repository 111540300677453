import React from "react";
import DuplicateSubmissionsDataReview from "./DuplicateSubmissionsDataReview";
import { ModalWrapper } from "../../../components/ModalWrapper";

export const DuplicateSubmissionsModal = ({
  onClose,
  contentData,
  dupSubData,
}) => {
  const isOpen = !!contentData || !!dupSubData;

  return (
    <ModalWrapper
      title={"Duplicate submissions match report"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DuplicateSubmissionsDataReview data={dupSubData} />
    </ModalWrapper>
  );
};
