import { useState, useEffect } from "react";

import DataTable from "../../../components/table/DataTable";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import SpinnerOverlay from "../../../components/SpinnerOverlay/SpinnerOverlay";
import Pagination from "../../../components/Pagination/Pagination";
import NoContentMessage from "../../../components/NoContentMessage/NoContentMessage";
import {
  ReportActionButtons,
  SECTION_KEYS,
} from "../../screened-manuscripts/components/ReportActionButtons";
import {
  ApplyAction,
  ColorizeStatusText,
} from "../../../components/ApplyAction";
import {
  convertISOToReadableDate,
  getAmbientScreeningSubmissionById,
  showErrorToast,
} from "../../../helpers";
import { MDBBtn } from "mdb-react-ui-kit";
import { exportAllManuscriptsToExcel } from "../export";
import { SignalReportModal } from "../../../components";
import { dropdownOptions } from "../dropdownOptions";
import { columns } from "../columns";
import useDuplicateSubmissions from "../hooks/useDuplicateSubmissions";
import { DuplicateSubmissionsModal } from "../components/DuplicateSubmissionsModal";

const ManuscriptsTab = ({ type, isActiveTab }) => {
  const [selectedItem, setSelectedItem] = useState({
    index: null,
    visibleSection: SECTION_KEYS.ALL,
  });
  const [fetchedData, setFetchedData] = useState(null);

  const {
    data,
    fetchData,
    setData,
    setTriggerOrMatchStatus,
    isLoading,
    setIsLoading,
    error,
    pageCount,
    currentPage,
    setCurrentPage,
  } = useDuplicateSubmissions({ type, isActiveTab });

  const updateStatus = async (index, status) => {
    const newData = [...data];

    if (type === "wo_status") {
      const previousElement = { ...newData[index] };
      newData.splice(index, 1);
      setData(newData);

      const { match_id, persistent_id } = previousElement;
      try {
        await setTriggerOrMatchStatus(match_id, persistent_id, status);
        await fetchData();
      } catch {
        showErrorToast();
        newData.splice(index, 0, previousElement);
        setData(newData);
      }
    } else {
      const previousStatus = newData[index].submission_status;
      newData[index].submission_status = status;

      const { match_id, persistent_id } = newData[index];
      try {
        await setTriggerOrMatchStatus(match_id, persistent_id, status);
      } catch {
        showErrorToast();
        newData[index].submission_status = previousStatus;
      } finally {
        setData(newData);
      }
    }
  };

  const handleIconClick = (index, buttonKey) => {
    setSelectedItem({ index, visibleSection: buttonKey });
  };

  const handleRowClick = (index) => {
    if (index === null) {
      return;
    }
    setSelectedItem({ index, visibleSection: SECTION_KEYS.DUPSUB });
  };

  const onActionSelect = (index, status) => {
    updateStatus(index, status);
  };

  const onActionUndo = (index) => {
    updateStatus(index, null);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const dataWithActions = data?.map((element, index) => ({
    ...element,
    raw_match_status: element.match_status,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    match_status: <ColorizeStatusText statusText={element.match_status} />,
    action: (
      <div className="d-flex">
        <ApplyAction
          index={index}
          status={data[index].submission_status}
          selectAction={onActionSelect}
          undoAction={onActionUndo}
          options={dropdownOptions}
        />
      </div>
    ),
    signals: (
      <ReportActionButtons
        signals={element.signals}
        onIconClick={(buttonKey) => handleIconClick(index, buttonKey)}
      />
    ),
    clickEvent: () => handleRowClick(index),
  }));

  const fetchAmbientScreeningDataIfNeeded = async (index) => {
    const { persistent_id, signals } = data[index];
    if (persistent_id && signals?.some((s) => s !== "duplicateSubmission")) {
      setIsLoading(true);
      try {
        const { data: fetchedData } = await getAmbientScreeningSubmissionById(
          persistent_id
        );
        setFetchedData(fetchedData);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleExport = () => {
    if (type === "all") {
      exportAllManuscriptsToExcel(dataWithActions, "AllManuscripts");
    }
  };

  useEffect(() => {
    if (selectedItem.index !== null) {
      fetchAmbientScreeningDataIfNeeded(selectedItem.index);
    } else {
      setFetchedData(null);
    }
  }, [selectedItem, data]);

  const resetModalState = () => {
    setFetchedData(null);
    setSelectedItem({
      index: null,
      visibleSection: SECTION_KEYS.ALL,
    });
  };

  const hasNonDupSubSignals =
    selectedItem.index !== null &&
    !isLoading &&
    data[selectedItem.index]?.signals?.some(
      (x) => x !== "duplicateSubmissions"
    );

  const modalProps = {
    dupSubData: data[selectedItem.index],
    contentData: fetchedData,
    visibleSection: selectedItem.visibleSection,
    onClose: resetModalState,
  };

  const shouldRenderModal =
    !isLoading &&
    selectedItem.index !== null &&
    (hasNonDupSubSignals ? fetchedData : true);

  return (
    <SpinnerOverlay active={isLoading} centerOnScreen={true}>
      {shouldRenderModal &&
        (hasNonDupSubSignals ? (
          <SignalReportModal {...modalProps} />
        ) : (
          <DuplicateSubmissionsModal {...modalProps} />
        ))}
      {error && <ErrorMessage />}
      {!error && !isLoading && data?.length === 0 && <NoContentMessage />}
      {!error && data?.length > 0 && (
        <>
          {type === "all" && (
            <div className="d-flex justify-content-end mb-3">
              <MDBBtn
                disabled={isLoading}
                color="primary"
                className="px-4"
                onClick={handleExport}
              >
                Export
              </MDBBtn>
            </div>
          )}
          <DataTable columns={columns} rows={dataWithActions} />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </SpinnerOverlay>
  );
};

export default ManuscriptsTab;
