import { axiosInstance } from "./axiosSetup";

export async function getDuplicateSubmissionsReportsByType(
  startrow,
  maxrows,
  type,
  filter
) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      maxrows: maxrows,
      startrow: startrow,
      filter: filter,
      type: type, // 'all' | 'triggers' | 'matched' | 'wo_status'
    },
    path: "/reports/dupsub/matches",
    method: "get",
  });
}

export async function getAllDuplicateSubmissions(startrow, maxrows, filter) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      maxrows: maxrows,
      startrow: startrow,
      filter: filter,
    },
    path: "/reports/dupsub/submissions",
    method: "get",
  });
}

export async function getDuplicateSubmissionsCount() {
  return axiosInstance.post(`hub-api/proxy`, {
    path: "/reports/dupsub/matches/count",
    method: "get",
  });
}

export async function getDuplicateSubmissionById(id) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      type: "duplicateSubmissions",
    },
    path: `/reports/screened/${id}`,
    method: "get",
  });
}

export async function updateTriggerOrMatchStatus(id, persistentId, submissionStatus) {
  return axiosInstance.post(`hub-api/proxy`, {
    path: `/reports/dupsub/matches/${id}`,
    method: "patch",
    body: { persistentId, submissionStatus }
  });
}

export async function getAllAmbientScreeningSubmissions(startrow, maxrows, filter, type) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      maxrows, startrow, filter, type
    },
    path: `/reports/ambient/manuscripts`,
    method: "get",
  });
}

export async function getAmbientScreeningSubmissionById(id) {
  return axiosInstance.post(`hub-api/proxy`, {
    queryStringParameters: {
      type: "ambientScreening",
    },
    path: `/reports/screened/${id}`,
    method: "get",
  });
}

export async function updateAmbientScreeningSubmissionStatus(id, submissionStatus) {
  return axiosInstance.post(`hub-api/proxy`, {
    path: `/reports/ambient/manuscripts/${id}`,
    method: "patch",
    body: { submissionStatus }
  });
}
