export const baseColumns = [
  {
    label: "Received",
    field: "timestamp",
  },
  {
    label: "Journal",
    field: "journal_name",
    width: 120,
  },
  {
    label: "Manuscript\u00A0ID",
    field: "manuscript_id",
  },
  {
    label: "Manuscript title",
    field: "title",
  },
  {
    label: "Signals",
    field: "signals",
    width: 120,
  },
];

export const ambientScreeenedColumns = [
  ...baseColumns,
  {
    label: "Status",
    field: "submission_status",
    width: 120,
  },
  {
    label: "Action",
    field: "action",
    width: 190,
  },
];
