import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import {
  renderListItems,
  renderWordFilePropertyItem,
} from "./MetadataAnalysisDataUtils";
import { ReportActionButtons } from "../../screened-manuscripts/components/ReportActionButtons";
import { InfoTooltip } from "../../../components";

const MetadataAnalysisDataReview = ({ data, withSubHeader = false }) => {
  const wordFileProperties =
    data?.screening_outcomes?.find((x) => x.type === "wordFileProperties")
      ?.items ?? [];

  return (
    <>
      {wordFileProperties.length > 0 && (
        <MDBCard>
          {withSubHeader && (
            <MDBCardHeader className="d-flex">
              <ReportActionButtons
                signals={["wordFileProperties"]}
                showTooltip={false}
              />
              <MDBCardTitle className="pt-2 ps-2">
                Word file metadata
                <InfoTooltip
                  tooltipText={
                    <>
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum."
                    </>
                  }
                />
              </MDBCardTitle>
            </MDBCardHeader>
          )}
          <MDBCardBody>
            {renderListItems(wordFileProperties, renderWordFilePropertyItem)}
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default MetadataAnalysisDataReview;
