import { useState, useEffect, useCallback } from "react";
import {
  MDBContainer,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { showErrorToast, fetchManuscriptCounts } from "../../helpers";
import SpinnerOverlay from "../../components/SpinnerOverlay/SpinnerOverlay";
import { CountCard } from "../../components";
import UnderReviewManuscriptsTab from "./tabs/UnderReviewManuscriptsTab";
import AmbientScreenedManuscriptsTab from "./tabs/AmbientScreenedManuscriptsTab";
import SearchInput from "../../components/SearchInput/SearchInput";
import { useAppContext } from "../../components/AppContext";
import { useAuth } from "../../components/Auth";

const ScreenedManuscripts = () => {
  const { components } = useAuth();
  const { setSearchInput } = useAppContext();

  const initialTab = components.ambientScreening ? "inbox" : "under_review_manuscripts";

  const [currentTab, setCurrentTab] = useState(initialTab);
  const [isLoading, setIsLoading] = useState(false);
  const [manuscriptCounts, setManuscriptCounts] = useState({
    duplicateSubmissions: 0,
    ambientScreened: 0,
  });

  const baseTabConfig = [
    {
      key: "under_review_manuscripts",
      label: "Under review",
      component: UnderReviewManuscriptsTab,
    },
    {
      key: "ambient_screened",
      label: "Ambient screened",
      type: "all",
      component: AmbientScreenedManuscriptsTab,
    },
  ];

  const additionalTabConfig = [
    {
      key: "inbox",
      label: "Inbox",
      type: "wo_status",
      showActionColumn: true,
      component: AmbientScreenedManuscriptsTab,
    },
    {
      key: "w_signals",
      label: "With signals",
      type: "w_signals",
      showActionColumn: true,
      component: AmbientScreenedManuscriptsTab,
    },
  ];

  const combinedTabConfig = components.ambientScreening
    ? [...additionalTabConfig, ...baseTabConfig]
    : baseTabConfig;


  useEffect(() => {
    const getManuscriptCounts = async () => {
      setIsLoading(true);
      try {
        const counts = await fetchManuscriptCounts();
        setManuscriptCounts(counts);
      } catch (error) {
        showErrorToast("Failed to load manuscript counts. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    getManuscriptCounts();
  }, []);

  const handleTabClick = useCallback(
    (tabKey) => {
      if (currentTab !== tabKey) {
        setCurrentTab(tabKey);
      }
    },
    [currentTab]
  );

  const handleCountCardClick = useCallback((tabKey) => {
    setCurrentTab(tabKey);
  }, []);

  return (
    <MDBContainer className="my-5">
      <SpinnerOverlay active={isLoading}>
        <div className="d-flex justify-content-center m-5">
          <CountCard
            count={manuscriptCounts.duplicateSubmissions}
            title="Manuscripts"
            subtitle="Under Review"
            onClick={() => handleCountCardClick("under_review_manuscripts")}
          />
          <CountCard
            count={manuscriptCounts.ambientScreened}
            title="Manuscripts"
            subtitle="Ambient Screened"
            onClick={() => handleCountCardClick("ambient_screened")}
          />
        </div>
      </SpinnerOverlay>

      <MDBRow className="mb-3">
        <MDBCol md="auto" className="ms-4">
          <MDBTabs>
            {combinedTabConfig.map(({ key, label }) => (
              <MDBTabsItem key={key}>
                <MDBTabsLink
                  active={currentTab === key}
                  onClick={() => handleTabClick(key)}
                >
                  {label}
                </MDBTabsLink>
              </MDBTabsItem>
            ))}
          </MDBTabs>
        </MDBCol>
        <MDBCol sm={8} md={6} lg={4} className='ms-auto'>
          <SearchInput
            placeholder="Search by manuscript ID or title"
            onSubmit={setSearchInput}
          />
        </MDBCol>
      </MDBRow>

      <MDBTabsContent>
        {combinedTabConfig.map(({ key, component: TabComponent, type, showActionColumn }) => (
          <MDBTabsPane key={key} show={currentTab === key}>
            <TabComponent
              isActiveTab={currentTab === key}
              type={type}
              showActionColumn={showActionColumn}
            />
          </MDBTabsPane>
        ))}
      </MDBTabsContent>
    </MDBContainer>
  );
};

export default ScreenedManuscripts;
